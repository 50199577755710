
            @import 'src/styles/mixins.scss';
        
.rotate-icon {
    transition: transform 0.7s;
    transform: rotate(180deg);
}

.divider {
    border-bottom: 1px solid lightgray;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}
