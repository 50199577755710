
            @import 'src/styles/mixins.scss';
        
.infoCardContainer {
    box-shadow: 0px 4px 16.4px 0px #d4e0fa;
}

.blogCardContainer {
    box-shadow: 0px 3px 23px 0px #d2e0fb;
    transition: transform 0.7s;
    &:hover {
        transform: scale(1.05);
    }
}
