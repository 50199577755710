
            @import 'src/styles/mixins.scss';
        
.right {
    flex-direction: column;
    @include lg {
        flex-direction: row;
    }
}
.left {
    flex-direction: column;
    @include lg {
        flex-direction: row-reverse;
    }
}
