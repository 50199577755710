
            @import 'src/styles/mixins.scss';
        
.image-bg-section {
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("/assets/images/secondSectionBg.png");
    }
}
.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-block: 6rem;

    @include lg {
        height: 100vh;
    }
}

.left {
    align-items: start;
    background-position: left;
}
.right {
    align-items: flex-end;
    background-position: right;
    h2 {
        @include lg {
            margin-right: 4rem;
        }
    }
}
.second-section {
    &::before {
        background-image: url("/assets/images/secondSectionBg.png");
    }
}
.fourth-section {
    &::before {
        // @media screen and (max-width: 768px) {
        //     background-color: rgb(0 0 0 / 80%);
        //     background-blend-mode: multiply;
        // }
        background-color: rgb(0 0 0 / 80%);
        background-blend-mode: multiply;
        @include lg {
            background-color: rgb(0 0 0 / 60%);
            background-blend-mode: multiply;
        }
        background-image: url("/assets/images/fourthSectionBg.png");
    }
}
.eighth-section {
    &::before {
        background-image: url("/assets/images/eighthSectionBg.png");
    }
}

.mirror {
    -moz-transform: scale(1, -1);
    -webkit-transform: scale(1, -1);
    -o-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
}
